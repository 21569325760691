// Please keep things ordered
// Fact types

export const CSN_SEARCH_SIMPLE_CLICK_FACT_TYPE = 'csn_search_simple_click';
export const CSN_ADD_TO_CART_FACT_TYPE = 'csn_search_add_to_cart';
export const CSN_REMOVE_FROM_CART_FACT_TYPE = 'csn_search_remove_from_cart';
export const CSN_SUBMIT_REQUEST_FACT_TYPE = 'csn_submit_request';
export const CSN_SEARCH_BAR_FACT_TYPE = 'csn_search_bar';
export const CSN_AD_CLICK_FACT_TYPE = 'csn_ad_click';
export const CSN_CERTIFIED_VIEW_FACT_TYPE = 'csn_certified_view';
export const CSN_AD_VIEW_FACT_TYPE = 'csn_ad_view';
export const CSN_MAP_VIEW_FACT_TYPE = 'csn_map_view';
export const CSN_SEARCH_FILTERS_FACT_TYPE = 'csn_search_filters';
export const CSN_SEARCH_APP_FILTERS_FACT_TYPE = 'csn_search_app_filters';
export const CSN_VENUE_RATES_SIMPLE_CLICK_TYPE = 'csn_venue_rates_simple_click';

// Ad types
export const FOUR_DIAMOND_AD_TYPE = 'FOUR_DIAMOND';
export const THREE_DIAMOND_LIST_AD_TYPE = 'THREE_DIAMOND_LIST';
export const DIAMOND_PLUS_AD_TYPE = 'DIAMOND_PLUS';
export const COMPETITIVE_MARKET_AD_TYPE = 'COMPETITIVE_MARKET';
export const VIDEO_AD_TYPE = 'VIDEO';

// Other constants
export const PAGE_TARGET_TYPE_GUEST = 'Guest';
export const SEARCH_RESULTS_PAGE = 'search_results';
export const MEETING_ROOM_GRID = 'meeting_room_section';
export const SEARCH_RESULTS_FILTERS_PAGE = 'search_results_filters';
export const VENUE_RATES_MODAL = 'venue_rates_modal';
export const FACT_VERSION = '1.0';
export const FACT_VERSION_SHOPPING_CART = '1.3';
export const ADD_TO_CART_VERSION = '1.2';
export const SEARCH_RESULT_LIMIT_PER_PAGE = 25;
export const VENUE_IMAGE_CAROUSEL_CLICK_ENTITY = 'venue_image_carousel_v2';
export const MAP_MARKER_CLICK_ENTITY = 'map_pin';
export const MAP_SEARCH_AS_MOVE_CLICK_ENTITY = 'map_search_as_move';
export const MAP_SEARCH_AS_MOVE_CHECKED = 'checked';
export const MAP_SEARCH_AS_MOVE_UNCHECKED = 'unchecked';
export const SEARCH_TYPE_AUTOCOMPLETE = 'autocomplete';

export const HEADER = 'Header';
export const FOOTER = 'Footer';
export const CSN_FACT_SOURCE_VENUE_CARD = 'venue_card';
export const CSN_FACT_SOURCE_MAP = 'map_view';
export const SHOPPING_CART = 'shopping_cart';
export const VENUE_CARD_CLICK_TYPE = 'venue_card_v2';
export const MAP_MARKER_CLICK_TYPE = 'map_pin_click';
export const MAP_MARKER_HOVER_TYPE = 'map_pin_hover';
export const PRODUCT = 'csn_search_app';
export const AD_CLICK_TARGET_VENUE_PROFILE = 'venue_profile';
export const VENUE_CARD_PAGE_TYPE = 'venue_card';

// IBK Fact Types
export const INSTANT_BOOK_WIDGET_TRIGGER_CLICK_TYPE = 'instant_book_widget_trigger';
export const INSTANT_BOOK_WIDGET_AVAILABILITY_CLICK_TYPE = 'instant_book_widget_availibility';
export const INSTANT_BOOK_FROM_WIDGET_CLICK_TYPE = 'instant_book_from_widget';

// Venue rates constants

export const START_DATE_DROPDOWN = 'start_date_drop_down';
export const CHART_VIEW_MODE = 'chart_view_mode';
export const TABLE_VIEW_MODE = 'table_view_mode';
export const CITY_NAME = 'city_name';
export const CITY_GUIDE = 'city_guide';
export const MOUSE_HOVER_CHART = 'mouse_hover_chart';
export const VENUE_RATES_FILTER_SECTION = 'venue_rates_filter_section';
export const VIEW_PRICES_BY_MONTH_AND_YEAR = 'view_prices_by_month_and_year';
export const END_DATE_DROPDOWN = 'end_date_drop_down';
export const VIEW_VENUE_RATES_GRAPH = 'view_venue_rates_graph';
export const HIDE_VENUE_RATES_GRAPH = 'hide_venue_rates_graph';

// Returns the Current URL for the respective route
export function getPageUrl(): string {
  if (typeof window !== 'undefined' && window.location && window.location.href) {
    return window.location.href;
  }
  return '';
}

// Hello World -> hello_world
export function convertToSnakeCase(inputString: string | undefined): string {
  if (typeof inputString === 'undefined') return '';
  return inputString.toLowerCase().replace(/\s+/g, '_');
}
