export const queryStringConstants = {
  USE_SHOPPING_CART_VENUE_ORDER: 'useShoppingCartVenueOrder',
  SUPPLIER_IDS: 'supplierIds',
  MEETING_SPACE: 'needMeetingSpace',
  SLEEPING_ROOM: 'needSleepingRoom',
  ROOMS_PER_NIGHT: 'roomsPerNight',
  ENV: 'env',
  ENVIRONMENT: 'environment',
  BACK_URL: 'backUrl',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  IS_PREVIEW: 'isPreview',
  IS_PLANNER_PROMOTION: 'isPlannerPromotion',
  PROMOTION_IDS: 'promoIds',
  SUPPLIER_ID_XML: 'ofrgs',
  RFP_TYPE: 'type',
  AD_TYPE: 'aType',
  AD_POSITION: 'aPos',
  AD_CODE: 'aCode',
  AD_COMPETITIVE_SET_FLAG: 'aComp',
  AD_NEED_DATES_FLAG: 'aNeed',
  AD_COMPETITIVE_VENUE_ID: 'aCompId',
  APP: 'app',
  EVENT_ID: 'eventId',
  NORMANDY_PAGE_ID: 'pagetype',
  RFP_ID: 'rfpId',
  PEAK_MEETING_ROOMS: 'peakMeetingRooms',
  ATTENDEES: 'attendees',
  MEASUREMENT_UNIT: 'measurementUnit',
  METRIC: 'METRIC',
  IMPERIAL: 'IMPERIAL',
  LAST_LOCATION: 'lastLocation',
  REFERRAL: 'ref',
  RFP: 'rfp',
  FILTER: 'filter',
  DISTANCE_QUERY: 'distanceQuery',
  LIMIT: 'limit',
  SORT: 'sort',
  VIEW: 'view',
  LAT: 'lat',
  LNG: 'lng',
  PAGE_NUMBER: 'p',
  GEO: 'geo',
  DISPLAY_DISTANCE: 'displayDistance',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  UTM_CAMPAIGN: 'utm_campaign',
  UTM_TERM: 'utm_term',
  UTM_CONTENT: 'utm_content',
  UTM_ID: 'utm_id',
  CID: 'cid',
  SEARCH_RESULTS_RANK: 'rank',
  SEARCH_TERM: 'term',
  IMAGE_RANK: 'img_rank',
  SOURCE: 'src',
  TIMESTAMP: 'ts',
  PREV_GEO: 'prevGeo',
  AI: 'ai',
  HUB_CATEGORY: 'hubCategory',
  CREATION_SOURCE: 'creationSource'
};

export enum VIEW {
  MAP = 'MAP',
  LIST = 'LIST',
  FULL_MAP = 'FULL_MAP'
}
