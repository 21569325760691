import { isEmpty } from 'lodash';
import { APP, PHOENIX } from '@/constants/routeConstants';

export type QueryParam = {
  [key: string]: (string | boolean | number)[];
};

export type LocationObject = {
  pathname: string;
  query: QueryParam;
  hash: string | null;
  state?: string;
};

/**
 * URL Builder:
 *  - Creates an url out of all passed in paths, query parameters, picks the last hash parameter set
 *  - Supports adding query parameters individually or as a string
 *  - Can create a url string, or a url object for Links
 */
class UrlBuilder {
  private _path: string[];

  private _queryParam: QueryParam;

  private _hashParam: string | null;

  private _state?: string;

  constructor(isPhoenix = false) {
    this._path = [];
    this._queryParam = {};
    this._hashParam = null;

    if (isPhoenix) {
      this.addQueryParam(APP, PHOENIX);
    }
  }

  public getPath(): string {
    return this._path.join('/');
  }

  public getHashParam(): string {
    return this._hashParam ? this._hashParam : '';
  }

  public getQueryObject(): QueryParam {
    const queryParamsObj = {};
    for (const [key, [value]] of Object.entries(this._queryParam)) {
      if (Object.prototype.hasOwnProperty.call(this._queryParam, key)) {
        queryParamsObj[key] = value;
      }
    }
    return queryParamsObj;
  }

  public getQueryParams(): string {
    let queryParamsString = '';
    for (const key of Object.keys(this._queryParam)) {
      const values = this._queryParam[key];
      for (const value of values) {
        queryParamsString += `${key}=${value}&`;
      }
    }
    return queryParamsString.slice(0, -1);
  }

  public addQueryParam(
    name: string,
    value: string | number | boolean | undefined,
    enableZeroAsQueryParamValue = false,
    overwriteExisting = false
  ): UrlBuilder {
    if ((enableZeroAsQueryParamValue && value === '0') || (!!value && value !== '')) {
      if (!overwriteExisting && Object.prototype.hasOwnProperty.call(this._queryParam, name)) {
        this._queryParam[name].unshift(value);
      } else {
        this._queryParam[name] = [value];
      }
    }
    return this;
  }

  public addQueryParamList(name: string, list: (string | boolean | number)[]): UrlBuilder {
    if (list && list.length > 0) {
      list.map(item => this.addQueryParam(name, item));
    }
    return this;
  }

  setState(state) {
    this._state = state;
    return this;
  }

  public addPath(path: string): UrlBuilder {
    if (!path || path.length === 0) {
      return this;
    }
    let pushPath = path.charAt(path.length - 1) === '/' ? path.substring(0, path.length - 1) : path;
    pushPath = pushPath.charAt(0) === '/' ? pushPath.substring(1) : pushPath;
    if (pushPath && pushPath.length > 0) {
      this._path.push(pushPath);
    }
    return this;
  }

  public replacePath(path: string): UrlBuilder {
    this._path = [];
    this._path.push(path);
    return this;
  }

  public getLocationObject(): LocationObject {
    return {
      pathname: this.getPath(),
      query: this.getQueryObject(),
      hash: this.getHashParam()
    };
  }

  public mergeQueryObject(queryObject: QueryParam, enableZeroAsQueryParamValue = false): UrlBuilder {
    Object.keys(queryObject).forEach(key => {
      const value = queryObject[key];
      if (Array.isArray(value)) {
        this.addQueryParamList(key, value);
      } else {
        this.addQueryParam(key, value, enableZeroAsQueryParamValue);
      }
    });
    return this;
  }

  public mergeLocationObject(locationObject: LocationObject): UrlBuilder {
    if (!locationObject) {
      return this;
    }
    if (Object.prototype.hasOwnProperty.call(locationObject, 'pathname')) {
      this.replacePath(locationObject.pathname);
    }
    if (Object.prototype.hasOwnProperty.call(locationObject, 'query')) {
      this.mergeQueryObject(locationObject.query);
    }
    if (Object.prototype.hasOwnProperty.call(locationObject, 'hash')) {
      this._hashParam = locationObject.hash;
    }
    return this;
  }

  addSearchString(searchString, enableZeroAsQueryParamValue = false) {
    const joinedQueryParams = searchString.startsWith('?')
      ? searchString.substr(1, searchString.length - 1)
      : searchString;
    const queryParamPairs = joinedQueryParams.split('&');
    // eslint-disable-next-line  array-callback-return
    queryParamPairs.map(pair => {
      const pairKeyValue = pair.split('=');
      this.addQueryParam(pairKeyValue[0], pairKeyValue[1], enableZeroAsQueryParamValue);
    });
    return this;
  }

  clearQueryParams(filterFunction?) {
    const newQueryParam = {};
    if (filterFunction) {
      // eslint-disable-next-line  no-restricted-syntax
      for (const key in this._queryParam) {
        // eslint-disable-next-line  no-prototype-builtins
        if (this._queryParam.hasOwnProperty(key) && filterFunction(key)) {
          newQueryParam[key] = this._queryParam[key];
        }
      }
    }
    this._queryParam = newQueryParam;
    return this;
  }

  setHashParam(hashParam) {
    if (!hashParam) {
      return this;
    }
    this._hashParam = hashParam.charAt(0) === '#' ? hashParam : `#${hashParam}`;
    return this;
  }

  public getFullUrl(): string {
    let finalPath = this.getPath();
    if (!isEmpty(this._queryParam)) {
      finalPath = `${finalPath}?${this.getQueryParams()}`;
    }
    if (this._hashParam) {
      finalPath = `${finalPath}#${this.getHashParam()}`;
    }
    return finalPath;
  }
}

export default UrlBuilder;
