import { FetchClient } from '@cvent/fetch/FetchClient';

let client: FetchClient | null = null;

export default function getFetchClient(): FetchClient {
  if (client) {
    return client;
  }
  client = new FetchClient({ timeout: 10000 });
  return client;
}
